.pricing{
    display: flex;
    justify-content: center;
}
.pricing table{
    margin: 20px 0;
}
.pricing table td{
    padding: 5px 15px;
}
.pricing table th {
    padding: 10px;
    text-align: left;
    background-color: #b8bebc;
    color: black;
}
.pricing table tr:nth-child(odd){
    background-color: #dde8e5;
}