nav{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
    background-color: whitesmoke;
    border-bottom: 0.25px solid gray;
    position: sticky;
    top: 0;
    z-index: 5;
}
nav a{
    margin: 20px;
    color: black;
    text-decoration: none;
}
nav img{
    height: 70px;
}