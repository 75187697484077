.offer{
    padding: 20px;
}
.offer>div{
    display: flex;
    justify-content: center;
    margin: 2vh 0 5vh 0;
}
.offer .massageButton p{
    margin: 5px 10px;
    padding: 7px 12px;
    /* background-color: #d0d4d3; */
    border-radius: 2px;
    border: 1px solid black;
    cursor: pointer;
}
.offer .massageDesc{
    display: flex;
    flex-direction: column;
}
.offer .massageDesc h2{
    margin: 20px 0;
    text-align: center;
}
.offer .row{
    display: flex;
    flex-direction: row;
}
.offer .massageDesc p{
    font-size: 17.6px;
    text-align: justify;
}
.offer .massageDesc img{
    margin: 0 20px;
    max-width: calc(50vw - 60px);
}

@media(max-width: 1240px){
    .offer>div{
        flex-direction: column;
    }
    .offer>div p{
        text-align: center;
    }
    .offer .row{
        flex-direction: column;
    }
    .offer .row img{
        margin: 20px 0;
        max-width: 100%;
        justify-content: center;
    }
}