@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap');

.hero{
    display: flex;
}
.hero div{
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.hero div h1{
    margin-bottom: 20.2px;
    font-family: 'Dancing Script', cursive;
    font-size: 3rem;
}
.hero div p{
    font-size: 1.1rem;
}
.hero img{
    width: 60vw;
    border-radius: 29% 75% 100% 0% / 0% 67% 33% 100% ;
    filter: brightness(100%);
    user-select: none;
    height: 80vh;
}

@media(max-width: 800px){
    .hero{
        flex-direction: column;
    }
    .hero img{
        width: 100vw;
        height: auto;
        border-radius: 0;
    }
    
}