body #root{
    min-height: 100vh;
    display:flex;
    flex-direction: column;
}
#root footer{
    margin-top: auto;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
#root{
    /* background-color: whitesmoke; */
    background-color: rgb(244, 244, 237);
}