footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color:  #959897;
    border: 1px solid black;
}
footer>div{
    display: flex;
}
footer div div{
    /* background-color:  #b8bebc; */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    align-items: center;
}
footer p{
    margin: 5px 0;;
}
footer a{
    margin: 5px 0;
    width: max-content;
    color: black;
    text-decoration: none;
}
footer .author{
    justify-content: center;
    background-color: black;
    color: white;
}
footer .author a{
    color: rgb(173, 170, 170);
}