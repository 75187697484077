.contact div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2% 0;
    flex-wrap: wrap;
}
.contact div article{
    display: flex;
    align-items: center;
    margin: 20px 50px;
}
.contact div article div{
    display: flex;
    flex-direction: column;
}
.contact div article div a{
    color: #5c5687;
    text-decoration: none;
    transition: 0.3s;
}
.contact div article div a:hover{
    color: #00bcd4;
}
.contact div article svg{
    min-width: 70px;
    height: 70px;
    background: rgb(216, 208, 208);
    box-shadow: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 22px;
    padding: 18px;
    margin: 0 10px;
}

@media(max-width: 800px){
    .contact div{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .contact div article{
        display: flex;
        align-items: center;
        margin: 20px 50px;
    }
    .contact div article div{
        display: flex;
        flex-direction: column;
    }
}