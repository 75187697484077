.home section{
    display: flex;
    margin: 20px 0;
}
.home section div{
    flex: 1;
    padding: 20px;
}
.home section div h3{
    font-size: 1.5rem;
}
.home section div article{
    font-size: 1.1rem;
    text-align: justify;
}
.home .red{
    color: red;
    font-weight: bold;
}
.home .flex2 h4{
    margin-left: -20px;
}


@media(max-width: 800px){
    .home section{
        flex-direction: column;
    }
}